import { NgForOf, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RxState } from '@rx-angular/state';
import { LetDirective } from '@rx-angular/template/let';
import { combineLatestWith, switchMap, takeUntil } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { DestroyableComponent } from '@core/classes/destroyable-component';
import { PRODUCT_FILES_DROP_AREA, RECEIPT_FILES_DROP_AREA } from '@core/constants/app.constants';
import { TDocumentType } from '@core/enums/document-type';
import { Files, IDropArea } from '@core/interfaces/claims/files.interface';
import { Plan } from '@core/interfaces/plan/plan.interface';
import { IRetailer } from '@core/interfaces/stores/retailer.interface';
import { IIdentity, IIdentityString } from '@core/interfaces/util/identity.interface';
import { RetailersListStore } from '@core/store/retailersList/retailersList.store';
import { FormDateComponent } from '@shared/components/form-date/form-date.component';
import { TCommonFieldSize } from '@shared/components/form-field/common-field-size.enum';
import { FormInputComponent } from '@shared/components/form-input/form-input.component';
import { FormSelectComponent } from '@shared/components/form-select/form-select.component';
import { FormTextareaComponent } from '@shared/components/form-textarea/form-textarea.component';
import { FormGroupDirective } from '@shared/directives/template-forms/form-group.directive';
import { ThFormFieldComponent } from '@shared/form/form-field/form-field.component';
import { ThFormFieldBlockComponent } from '@shared/form/th-form-field-block/th-form-field-block.component';
import { ClaimControlsModule } from '@shared/modules/claim-controls/claim-controls.module';
import { ClaimDialogsCoreModule } from '@shared/modules/claim-dialogs/claim-dialogs-core.module';
import { FilesCarouselComponent } from '@shared/modules/files/components/files-carousel/files-carousel.component';
import {
  RetailerSelectComponent,
} from '@shared/modules/store-controls/components/retailer-select/retailer-select.component';
import { PlanInfoTemplateFormComponent } from '@shared/standalone/claim-forms/plan-info-template-form/plan-info-template-form.component';
import {
  FormFilesDropAreaComponent,
} from '@shared/standalone/file-controls/form-files-drop-area/form-files-drop-area.component';
import { WizardFormSectionComponent } from '@shared/wizard/wizard-form-section/wizard-form-section.component';

import {
  ParentPlansListStore,
} from '../../../../../../th-retailer/app/pages/plans/containers/protection-plan-form/store/parent-plans-list.store';
import { TProductType } from '../../../../core/enums/product-type.enum';
import {
  MjcProductTypeSelectComponent,
} from '../../components/mjc-product-type-select/mjc-product-type-select.component';
import { PlanWizardStore } from '../../store/plan-wizard.store';

interface IPlanInfoWizardStepState {
  planForm: FormGroup;
  activeProductIndex: number;
  plansLoading: boolean;
  plansLength: number;
  plansList: IIdentityString[];
  receiptFiles: Files[];
  productFiles: Files[];
  selectedRetailer: IRetailer;
  selectedManufacturer: IIdentityString;
}

@Component({
  selector: 'app-plan-info-wizard-step',
  templateUrl: './plan-info-wizard-step.component.html',
  styleUrls: ['./plan-info-wizard-step.component.scss'],
  standalone: true,
  imports: [
    PlanInfoTemplateFormComponent,
    ClaimDialogsCoreModule,
    ReactiveFormsModule,
    MatButtonModule,
    FormGroupDirective,
    FormInputComponent,
    FormTextareaComponent,
    FormDateComponent,
    LetDirective,
    ThFormFieldBlockComponent,
    ThFormFieldComponent,
    FormSelectComponent,
    NgIf,
    MatIconModule,
    RetailerSelectComponent,
    WizardFormSectionComponent,
    FormFilesDropAreaComponent,
    NgForOf,
    FilesCarouselComponent,
    ClaimControlsModule,
    MjcProductTypeSelectComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RxState, RetailersListStore],
})
export class PlanInfoWizardStepComponent extends DestroyableComponent {
  vm$ = this.state.select();
  store = inject(PlanWizardStore);
  minPurchaseDate = this.store.get('minPurchaseDate');
  maxPurchaseDate = this.store.get('maxPurchaseDate');

  dropAreas: IDropArea[] = [RECEIPT_FILES_DROP_AREA, PRODUCT_FILES_DROP_AREA];

  readonly TCommonFieldSize = TCommonFieldSize;
  readonly TProductType = TProductType;
  protected readonly TDocumentType = TDocumentType;

  constructor(
    private readonly state: RxState<IPlanInfoWizardStepState>,
    private readonly retailersListStore: RetailersListStore,
    private readonly parentPlansListStore: ParentPlansListStore,
    private readonly fb: FormBuilder,
  ) {
    super();
    this.state.connect(this.store.ws.stepChanged$.pipe(
      startWith(null),
      combineLatestWith(this.store.ws.selectNavigationList$()),
    ), (oldState) => {
      const activeProductIndex = this.store.currentProductIndex;
      const planForm = (this.store.form.get('plans') as FormArray).at(activeProductIndex) as FormGroup;
      return {
        ...oldState,
        planForm,
        activeProductIndex,
      };
    });
    this.state.connect('plansLength', this.store.form.get('plans').valueChanges.pipe(
      startWith(this.store.form.get('plans').value),
      map(plans => plans.length),
    ));
    this.state.select('planForm').pipe(
      switchMap((planForm) => planForm.get('coveredProduct.type').valueChanges),
      takeUntil(this.componentDestroyed$),
    ).subscribe(coveredProductType => {
      const productManufacturer = this.state.get('planForm').get('coveredProduct.crmManufacturerId');
      if (coveredProductType === TProductType.Jewelry) {
        productManufacturer.removeValidators(Validators.required);
        productManufacturer.setValue(null);
        productManufacturer.updateValueAndValidity({ emitEvent: false });
      } else {
        productManufacturer.setValidators(Validators.required);
        productManufacturer.updateValueAndValidity({ emitEvent: false });
      }
    });

    this.state.connect(this.state.select('planForm'), (oldState, planForm) => {
      const selectedRetailer = {
        id: planForm.get('info.retailerId').value,
        name: planForm.get('info.retailerName').value,
      } as IRetailer;


      const searchRequest = this.parentPlansListStore.get('searchRequest');
      const mjcProductTypeId = planForm.get('coveredProduct.type').value;
      if (mjcProductTypeId) {
        this.parentPlansListStore.updateState({
          searchRequest: {
            ...searchRequest,
            mjcProductType: mjcProductTypeId,
          },
        });
      }
      if (selectedRetailer.id) {
        this.parentPlansListStore.setRetailerId(selectedRetailer.id);
      }

      return {
        ...oldState,
        selectedRetailer,
        selectedManufacturer: {
          id: planForm.get('coveredProduct.crmManufacturerId').value,
          name: planForm.get('coveredProduct.manufacturerName').value,
        },
        receiptFiles: (planForm.get('files') as FormArray).value.filter((file: Files) => file.docType === TDocumentType.Receipt),
        productFiles: (planForm.get('files') as FormArray).value.filter((file: Files) => file.docType === TDocumentType.CustPhoto),
      };
    });

    this.state.connect('plansList', this.parentPlansListStore.select$('plans'));
    this.state.connect('plansLoading', this.parentPlansListStore.select$('loading'));

    this.retailersListStore.updateSearchRequest({
      page: 1,
    });
    this.retailersListStore.loadData();
  }

  goBack(): void {
    this.store.goBack();
  }

  addAnotherPlan(): void {
    this.store.addPlan();
    this.store.goNext();
  }

  goNext(): void {
    this.store.goNext();
  }

  removePlan(): void {
    this.store.removePlan();
  }

  retailerSelected(retailer: IRetailer): void {
    const planForm = this.state.get('planForm');
    const selectedRetailerId = this.parentPlansListStore.get('searchRequest', 'retailerId');
    if (selectedRetailerId !== retailer.id) {
      planForm.get('info.planId').reset();
      planForm.get('info.retailerName').setValue(retailer.name);
      this.parentPlansListStore.setRetailerId(retailer.id);
    }
  }

  manufacturerSelected(manufacturer: IIdentityString): void {
    const planForm = this.state.get('planForm');
    planForm.get('coveredProduct.manufacturerName').setValue(manufacturer.name);
  }

  removeFileIndex(filesFormArray: FormArray, fileIndex: number): void {
    const updatedFiles = filesFormArray.value.filter(file => fileIndex !== file.index);
    (filesFormArray as FormArray).clear();
    updatedFiles.forEach((file: Files) => {
      (filesFormArray as FormArray).push(this.fb.control(file));
    });
  }

  setSelectedPlanName(selectedPlan: Plan): void {
    const planForm = this.state.get('planForm');
    planForm.get('info.planName').setValue(selectedPlan.parentPlanName);
  }

  productTypeChanged(mjcProductType: IIdentity): void {
    const planForm = this.state.get('planForm');
    const searchRequest = this.parentPlansListStore.get('searchRequest');
    const selectedProductType = this.parentPlansListStore.get('searchRequest', 'mjcProductType');
    if (selectedProductType !== mjcProductType.id) {
      this.parentPlansListStore.updateState({
        searchRequest: {
          ...searchRequest,
          mjcProductType: mjcProductType.id,
        },
      });
      if (searchRequest.retailerId) {
        planForm.get('info.planId').reset();
        this.parentPlansListStore.loadData();
      }
    }
  }
}
